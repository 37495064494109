import 'index.css';

import {ThemeContainer, ThemeProvider} from '@dropbox/dig-foundations';
import App from 'App';
import {useSaveFeedbackAssistantSessions} from 'components/feedback_chat/chat_hooks';
import {useTheme} from 'hooks/use_theme';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {store} from 'store/store';

const Application = () => {
  const themeMode = useTheme();

  useSaveFeedbackAssistantSessions();

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider mode={themeMode}>
          <ThemeContainer rootElement={document.documentElement}>
            <App />
          </ThemeContainer>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Application />, document.getElementById('root'));
