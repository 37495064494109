/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CareerFrameworkContent } from '../models/CareerFrameworkContent';
import type { CareerFrameworksSearchResponse } from '../models/CareerFrameworksSearchResponse';
import type { CareerFrameworkTree } from '../models/CareerFrameworkTree';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CareerFrameworksService {

    /**
     * Search Career Frameworks
     * Searches for career frameworks that match the given query and returns a list of ids for the matching career frameworks
     * @param query
     * @param limit
     * @param regexFilter
     * @returns CareerFrameworksSearchResponse Successful Response
     * @throws ApiError
     */
    public static searchCareerFrameworksApiV1CareerFrameworkSearchGet(
        query: string,
        limit: number = 10,
        regexFilter: string = '',
    ): CancelablePromise<CareerFrameworksSearchResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/career_framework/search',
            query: {
                'query': query,
                'limit': limit,
                'regex_filter': regexFilter,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Career Framework From Id
     * Returns the career framework for a given career framework id
     * @param careerFrameworkId
     * @returns CareerFrameworkContent Successful Response
     * @throws ApiError
     */
    public static getCareerFrameworkFromIdApiV1CareerFrameworkCareerFrameworkIdGet(
        careerFrameworkId: string,
    ): CancelablePromise<CareerFrameworkContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/career_framework/{career_framework_id}',
            path: {
                'career_framework_id': careerFrameworkId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Career Frameworks Structure
     * Returns the structure of all career frameworks
     * @returns CareerFrameworkTree Successful Response
     * @throws ApiError
     */
    public static getCareerFrameworksStructureApiV1CareerFrameworksGet(): CancelablePromise<CareerFrameworkTree> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/career_frameworks',
        });
    }

}
