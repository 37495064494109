import {ManagerFeedbackAssistant} from 'components/manager_feedback/manager_feedback';
import {Helmet} from 'react-helmet';

export const ManagerFeedback = () => {
  return (
    <>
      <Helmet>
        <title>Manager Feedback Assistant - SPRiTEs-GPT</title>
      </Helmet>
      <ManagerFeedbackAssistant />
    </>
  );
};
