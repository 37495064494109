/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Disclaimer Type Enum
 */
export enum DisclaimerType {
    STAKEHOLDER_FEEDBACK = 'stakeholder_feedback',
    MANAGER_ASSISTANT = 'manager_assistant',
}
