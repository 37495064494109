import {createSelector} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {
  CareerFrameworkIndividuals,
  Dropboxers,
} from 'store/features/feedback_chat/types';

export const getFeedbackChatState = (state: RootState) => state.feedbackChat;

export const getDropboxersFromSessions = createSelector(
  getFeedbackChatState,
  (feedbackChatState) => {
    return Object.keys(feedbackChatState.sessions)
      .filter(
        (dropboxerId) =>
          dropboxerId !== 'default' &&
          feedbackChatState.sessions[dropboxerId].individual
      )
      .reduce((dropboxers, dropboxerId) => {
        const dropboxer = feedbackChatState.sessions[dropboxerId].individual;
        if (!dropboxer) {
          return dropboxers;
        }

        return {...dropboxers, [dropboxerId]: dropboxer} as Dropboxers;
      }, {} as Dropboxers);
  }
);

export const getCareerFrameworkIndividualsFromSessions = createSelector(
  getFeedbackChatState,
  (feedbackChatState) => {
    return Object.keys(feedbackChatState.sessions)
      .filter(
        (cfId) =>
          cfId !== 'default' && feedbackChatState.sessions[cfId].individual
      )
      .reduce((careerFrameworkIndividuals, cfId) => {
        const careerFrameworkIndividual =
          feedbackChatState.sessions[cfId].individual;
        if (!careerFrameworkIndividual) {
          return careerFrameworkIndividuals;
        }

        return {
          ...careerFrameworkIndividuals,
          [cfId]: careerFrameworkIndividual,
        } as CareerFrameworkIndividuals;
      }, {} as CareerFrameworkIndividuals);
  }
);

export const getFeedbackAssistantSessions = createSelector(
  getFeedbackChatState,
  (feedbackChatState) => feedbackChatState.sessions
);
