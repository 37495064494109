/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContinueChatSession } from '../models/ContinueChatSession';
import type { FeedbackQuotes } from '../models/FeedbackQuotes';
import type { FeedbackSummary } from '../models/FeedbackSummary';
import type { Message } from '../models/Message';
import type { NewChatSession } from '../models/NewChatSession';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OpenAiService {

    /**
     * Start Chat
     * Starts a new feedback chat session with the OpenAI chatbot.
     * `identifier` can be either a dropboxer id or a career framework id.
     * @param identifier
     * @returns NewChatSession Successful Response
     * @throws ApiError
     */
    public static startChatApiV1ChatStartGet(
        identifier: string,
    ): CancelablePromise<NewChatSession> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/chat/start',
            query: {
                'identifier': identifier,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Continue Chat
     * Endpoint for hadling responses from the user.
     * @param sessionId
     * @param requestBody
     * @returns ContinueChatSession Successful Response
     * @throws ApiError
     */
    public static continueChatApiV1ChatContinueSessionIdPost(
        sessionId: string,
        requestBody: Message,
    ): CancelablePromise<ContinueChatSession> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/chat/continue/{session_id}',
            path: {
                'session_id': sessionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Feedback Quotes
     * Returns feedback tagged with a core responsibility for a particular employee.
     * The feedback is only available for the manager of the employee.
     * @param employeeId
     * @param spritesCycle
     * @returns FeedbackQuotes Successful Response
     * @throws ApiError
     */
    public static getFeedbackQuotesApiV1FeedbackQuotesEmployeeIdGet(
        employeeId: string,
        spritesCycle?: (string | null),
    ): CancelablePromise<FeedbackQuotes> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/feedback_quotes/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'sprites_cycle': spritesCycle,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Feedback Summary
     * Returns feedback tagged with a core responsibility for a particular employee.
     * The feedback is only available for the manager of the employee.
     * @param employeeId
     * @param spritesCycle
     * @returns FeedbackSummary Successful Response
     * @throws ApiError
     */
    public static getFeedbackSummaryApiV1FeedbackSummaryEmployeeIdGet(
        employeeId: string,
        spritesCycle?: (string | null),
    ): CancelablePromise<FeedbackSummary> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/feedback_summary/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            query: {
                'sprites_cycle': spritesCycle,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
