import {Layout} from 'components/Layout';
import {PageSpinner} from 'components/shared/PageSpinner';

const Logout = () => {
  return (
    <Layout>
      <PageSpinner />
    </Layout>
  );
};

export {Logout};
