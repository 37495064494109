import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import type {UserResponse} from 'client/models/UserResponse';

export type PulseUser = UserResponse;

type AuthState = {
  pulseUser: PulseUser | null;
  authCheckComplete: boolean;
  showDisclaimer: boolean;
};

const initialState: AuthState = {
  pulseUser: null,
  authCheckComplete: false,
  showDisclaimer: false,
};

const setPulseUser = (
  state: AuthState,
  action: PayloadAction<PulseUser | null>
) => {
  const user = action.payload;
  state.pulseUser = user;
  state.authCheckComplete = true;
};

const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {
    setPulseUser,
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
