import {useGetReports} from 'components/manager_feedback/feedback_filters';

export const useDoesUserHaveAnyReports = () => {
  const {allReportsIds} = useGetReports();

  if (allReportsIds && allReportsIds.length > 0) {
    return true;
  }

  return false;
};
