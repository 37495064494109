import {Avatar, AvatarSizes} from '@dropbox/dig-components/avatar';
import cx from 'classnames';
import {useState} from 'react';
import {API_URL, nameToInitials} from 'utilities';

import styles from './ProfileAvatar.module.css';

interface ProfileAvatarProps {
  abbreviated_name?: string;
  display_name: string;
  profile_photo_url?: string;
  size?: AvatarSizes;
}

export const ProfileAvatar = ({
  abbreviated_name,
  display_name,
  profile_photo_url,
  size = 'standard',
}: ProfileAvatarProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const photoURL = profile_photo_url?.includes('https://')
    ? profile_photo_url
    : API_URL + profile_photo_url;

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <Avatar
      src={photoURL}
      hasNoOutline
      size={size}
      alt={display_name}
      onLoad={handleImageLoad}
      className={cx(styles.avatar, {[styles.avatarLoaded]: imageLoaded})}
    >
      {abbreviated_name || nameToInitials(display_name)}
    </Avatar>
  );
};
