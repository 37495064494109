import {Avatar} from '@dropbox/dig-components/avatar';
import {IconButton} from '@dropbox/dig-components/buttons';
import {LabelGroup} from '@dropbox/dig-components/combinations';
import {Tooltip} from '@dropbox/dig-components/dist/tooltips';
import {List} from '@dropbox/dig-components/list';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {CloseLine} from '@dropbox/dig-icons/assets';
import styles from 'components/dropboxer_search/people_view.module.css';
import {
  CareerFrameworkIndividuals,
  DropboxerId,
  Dropboxers,
  IndividualId,
  SelectedIndividualInfo,
  TypesOfIndividual,
} from 'store/features/feedback_chat/types';

type PeopleAndCareerFrameworkViewProps = {
  dropboxers: Dropboxers;
  careerFrameworkIndividuals: CareerFrameworkIndividuals;
  enableRemove?: boolean;
  onRemoveClick?: (individualId: IndividualId) => void;
  onSelectIndividual: (selectedIndividualId?: IndividualId) => void;
  selectedIndividualInfo?: SelectedIndividualInfo;
  title?: string;
};

export const PeopleAndCareerFrameworkView = ({
  dropboxers,
  careerFrameworkIndividuals,
  enableRemove,
  onRemoveClick,
  onSelectIndividual,
  selectedIndividualInfo,
  title,
}: PeopleAndCareerFrameworkViewProps) => {
  return (
    <>
      {title && (
        <Text size="large" isBold className={styles['people-view-title']}>
          {title}
        </Text>
      )}
      <List isSelectable>
        {Object.keys(careerFrameworkIndividuals).map((cfId) => {
          const careerFrameworkItem = careerFrameworkIndividuals[cfId];
          const currentIndividualInfo = {
            individualId: cfId,
            individualType: TypesOfIndividual.careerFrameworkIndividual,
          } as SelectedIndividualInfo;
          return (
            <>
              {careerFrameworkItem.name != undefined && (
                <List.Item
                  key={cfId}
                  onClick={() => {
                    onSelectIndividual(currentIndividualInfo.individualId);
                  }}
                  isSelected={selectedIndividualInfo?.individualId === cfId}
                >
                  <List.Accessory>
                    <Avatar hasNoOutline>{`${careerFrameworkItem.name.slice(
                      0,
                      2
                    )}`}</Avatar>
                  </List.Accessory>
                  <List.Content>
                    <LabelGroup withText={`${careerFrameworkItem.name}`} />
                  </List.Content>
                  {enableRemove && (
                    <List.Accessory>
                      <Tooltip
                        title="Will delete all messages in that chat with no ability to recover them!"
                        placement="top"
                      >
                        <IconButton
                          variant="transparent"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            onRemoveClick?.(cfId);
                          }}
                        >
                          <UIIcon src={CloseLine} />
                        </IconButton>
                      </Tooltip>
                    </List.Accessory>
                  )}
                </List.Item>
              )}
            </>
          );
        })}

        {Object.keys(dropboxers).map((dropboxerId) => {
          const dropboxer = dropboxers[dropboxerId];
          const currentIndividualInfo = {
            individualId: dropboxerId,
            individualType: TypesOfIndividual.dropboxer,
          } as SelectedIndividualInfo;
          return (
            <>
              {dropboxer.preferred_first_name != undefined && (
                <List.Item
                  key={dropboxerId}
                  onClick={() => {
                    onSelectIndividual(currentIndividualInfo.individualId);
                  }}
                  isSelected={
                    selectedIndividualInfo?.individualId === dropboxerId
                  }
                >
                  <List.Accessory>
                    <Avatar
                      hasNoOutline
                    >{`${dropboxer.preferred_first_name[0]}${dropboxer.preferred_last_name[0]}`}</Avatar>
                  </List.Accessory>
                  <List.Content>
                    <LabelGroup
                      withText={`${dropboxer.preferred_first_name} ${dropboxer.preferred_last_name}`}
                      withSubtext={`${dropboxer.job_title} - ${dropboxer.job_level}`}
                    />
                  </List.Content>
                  {enableRemove && (
                    <List.Accessory>
                      <Tooltip
                        title="Will delete all messages in that chat with no ability to recover them!"
                        placement="top"
                      >
                        <IconButton
                          variant="transparent"
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.stopPropagation();
                            onRemoveClick?.(dropboxerId);
                          }}
                        >
                          <UIIcon src={CloseLine} />
                        </IconButton>
                      </Tooltip>
                    </List.Accessory>
                  )}
                </List.Item>
              )}
            </>
          );
        })}
      </List>
    </>
  );
};

type PeopleViewProps = {
  dropboxers: Dropboxers;
  enableRemove?: boolean;
  onRemoveClick?: (dropboxerId: DropboxerId) => void;
  onSelectDropboxer: (dropboxerId?: DropboxerId) => void;
  selectedDropboxerId?: string;
  title?: string;
};

export const PeopleView = ({
  dropboxers,
  enableRemove,
  onRemoveClick,
  onSelectDropboxer,
  selectedDropboxerId,
  title,
}: PeopleViewProps) => {
  return (
    <>
      {title && (
        <Text size="large" isBold className={styles['people-view-title']}>
          {title}
        </Text>
      )}
      <List isSelectable>
        {Object.keys(dropboxers).map((dropboxerId) => {
          const dropboxer = dropboxers[dropboxerId];

          return (
            <List.Item
              key={dropboxerId}
              onClick={() => {
                onSelectDropboxer(dropboxerId);
              }}
              isSelected={selectedDropboxerId === dropboxerId}
            >
              <List.Accessory>
                <Avatar
                  hasNoOutline
                >{`${dropboxer.preferred_first_name[0]}${dropboxer.preferred_last_name[0]}`}</Avatar>
              </List.Accessory>
              <List.Content>
                <LabelGroup
                  withText={`${dropboxer.preferred_first_name} ${dropboxer.preferred_last_name}`}
                  withSubtext={dropboxer.job_title}
                />
              </List.Content>
              {enableRemove && (
                <List.Accessory>
                  <Tooltip
                    title="Will delete all messages in that chat with no ability to recover them!"
                    placement="top"
                  >
                    <IconButton
                      variant="transparent"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation();
                        onRemoveClick?.(dropboxerId);
                      }}
                    >
                      <UIIcon src={CloseLine} />
                    </IconButton>
                  </Tooltip>
                </List.Accessory>
              )}
            </List.Item>
          );
        })}
      </List>
    </>
  );
};
