import {Avatar} from '@dropbox/dig-components/avatar';
import {Button} from '@dropbox/dig-components/buttons';
import {Modal} from '@dropbox/dig-components/dist/modal';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {TextArea} from '@dropbox/dig-components/text_fields';
import {Text} from '@dropbox/dig-components/typography';
import {CollapsibleDropboxerOrCareerFrameworkSearchBar} from 'components/dropboxer_search/dropboxer_search';
import {PeopleAndCareerFrameworkView} from 'components/dropboxer_search/people_view';
import {
  useGetIndividuals,
  useGetSearchBarStrings,
  useHandleDropboxerAndCareerFrameworkSearchResultCallback,
  useRemoveSessionCallback,
  useSelectIndividualCallback,
  useSetSearchQueryCallback,
} from 'components/feedback_chat/chat_hooks';
import React from 'react';
import {useAppSelector} from 'store';
import {getPulseUser} from 'store/features/auth/selectors';
import {getFeedbackChatState} from 'store/features/feedback_chat/selectors';
import {
  IndividualId,
  TypesOfIndividual,
} from 'store/features/feedback_chat/types';

export const displayCopyAlert = () => {
  alert(
    'Please make all necessary edits, revisions, and additions necessary so that what you submit in Workday accurately reflects your experience and opinions.'
  );
};

export const MessageAvatar = ({
  messageType,
}: {
  messageType: 'bot_message' | 'user_message' | 'unsent_message';
}) => {
  const curUser = useAppSelector(getPulseUser);

  if (messageType === 'bot_message') {
    return (
      <Avatar size="medium" hasNoOutline>
        AI
      </Avatar>
    );
  }

  return (
    <Avatar
      src={curUser?.profile_photo_url ?? undefined}
      size="medium"
      hasNoOutline
    >
      {curUser?.abbreviated_name}
    </Avatar>
  );
};

export const AIMessage = ({text}: {text?: string}) => {
  if (text === undefined) {
    return (
      <Spinner
        size="medium"
        onAnimationStart={(e) => {
          e.currentTarget.scrollIntoView({
            behavior: 'smooth',
          });
        }}
      />
    );
  }

  return (
    <TextArea
      resizable="auto"
      rows={1}
      readOnly
      value={text}
      onCopy={displayCopyAlert}
    />
  );
};

export const UserMessage = ({
  handleEditMessage,
  handleSendMessage,
  messageText,
  messageType,
  unsentMessageRef,
}: {
  disabled?: boolean;
  handleEditMessage: (messageText: string) => void;
  handleSendMessage: (messageText: string) => Promise<void>;
  messageText: string;
  messageType: 'user_message' | 'unsent_message';
  unsentMessageRef: React.RefObject<HTMLTextAreaElement>;
}) => {
  const isSentUserMessage = messageType === 'user_message';
  const isUnsentMessage = messageType === 'unsent_message';
  const isEmptyMessage = messageText === undefined || messageText === '';

  const unsentMessageRefProp = isUnsentMessage ? {ref: unsentMessageRef} : {};

  return (
    <TextArea
      {...unsentMessageRefProp}
      resizable="auto"
      rows={2}
      readOnly={isSentUserMessage}
      onChange={(e) => {
        if (isSentUserMessage) {
          return;
        }
        handleEditMessage(e.currentTarget.value);
      }}
      onKeyDown={async (e) => {
        if (isSentUserMessage || isEmptyMessage) {
          return;
        }

        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          await handleSendMessage(messageText);
          unsentMessageRef.current?.focus({preventScroll: true});
          unsentMessageRef.current?.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }}
      value={messageText}
    />
  );
};

export const StartOverModal = ({
  isOpen,
  setIsOpen,
  onConfirmClick,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onConfirmClick: () => void;
}) => {
  return (
    <Modal open={isOpen}>
      <Modal.Header>
        <Modal.Title>Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Text variant="paragraph" tagName="p">
          {
            "This will delete all messages in this chat and you won't be able to recover them. Are you sure?"
          }
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="filled"
          onClick={() => {
            setIsOpen(false);
          }}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onConfirmClick();
            setIsOpen(false);
          }}
        >
          Yes, delete all messages
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const DropboxSearchBarController = () => {
  const {
    search: {query: searchQuery},
  } = useAppSelector(getFeedbackChatState);

  const {
    dropboxers,
    dropboxersDisplayed,
    careerFrameworkIndividuals,
    careerFrameworkIndividualsDisplayed,
    selectedIndividualInfo,
  } = useGetIndividuals();

  const selectedIndividual =
    selectedIndividualInfo == undefined
      ? undefined
      : selectedIndividualInfo.individualType == TypesOfIndividual.dropboxer
      ? dropboxers[selectedIndividualInfo.individualId]
      : careerFrameworkIndividuals[selectedIndividualInfo.individualId];
  const {peopleViewTitle, searchBarTitle} =
    useGetSearchBarStrings(selectedIndividual);

  const selectIndividual = useSelectIndividualCallback();
  const handleSelectIndividual = React.useCallback(
    (individualId?: IndividualId) => {
      const individual =
        individualId == undefined
          ? undefined
          : dropboxers[individualId] == undefined
          ? careerFrameworkIndividuals[individualId]
          : dropboxers[individualId];
      selectIndividual(individualId, individual);
    },
    [dropboxers, selectIndividual, careerFrameworkIndividuals]
  );
  const handleSearchResultChange =
    useHandleDropboxerAndCareerFrameworkSearchResultCallback();
  const handleSetSearchQuery = useSetSearchQueryCallback();
  const handleRemoveSession = useRemoveSessionCallback();

  return (
    <CollapsibleDropboxerOrCareerFrameworkSearchBar
      onSearchResultsChange={handleSearchResultChange}
      onSelectIndividual={handleSelectIndividual}
      selectedIndividualId={selectedIndividualInfo?.individualId}
      searchQuery={searchQuery}
      setSearchQuery={handleSetSearchQuery}
      title={searchBarTitle}
    >
      <PeopleAndCareerFrameworkView
        dropboxers={dropboxers}
        careerFrameworkIndividuals={careerFrameworkIndividuals}
        selectedIndividualInfo={selectedIndividualInfo}
        onSelectIndividual={handleSelectIndividual}
        title={peopleViewTitle}
        enableRemove={
          dropboxersDisplayed === 'sessions' ||
          careerFrameworkIndividualsDisplayed === 'sessions'
        }
        onRemoveClick={handleRemoveSession}
      />
    </CollapsibleDropboxerOrCareerFrameworkSearchBar>
  );
};
