/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Model for a feedback statement
 */
export type Quote = {
    text: string;
    type: Quote.type;
    core_responsibility: Quote.core_responsibility;
    feedback_id: string;
};

export namespace Quote {

    export enum type {
        STRENGTH = 'strength',
        DEVELOPMENT_AREA = 'development_area',
    }

    export enum core_responsibility {
        RESULTS = 'results',
        DIRECTION = 'direction',
        TALENT = 'talent',
        CULTURE = 'culture',
        CRAFT = 'craft',
    }


}

