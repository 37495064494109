import type {Dropbox, users} from '@dropbox/api-v2-client';
import {DropboxClient} from '@dropbox/product/auth/DropboxClient';

export class DropboxAPI {
  client: DropboxClient;

  constructor(client: DropboxClient) {
    this.client = client;
  }

  async getStormcrows(features: string[]): Promise<Record<string, string>> {
    try {
      const response = await this.client
        .withUserAuth()
        .stormcrowServicerGetAssignments({
          features: features.map((feature) => ({feature})),
        });

      if (!response.result.assignments) {
        return {};
      }
      return Object.fromEntries(
        response.result.assignments.map((assignment) => [
          assignment.feature,
          assignment.variant,
        ])
      );
    } catch (error: any) {
      return {};
    }
  }

  // Get account info from Dropbox API
  async getAccountDetails(): Promise<null | users.FullAccount> {
    return this.client
      .withUserAuth()
      .usersGetCurrentAccount()
      .then((response) => {
        return response.result;
      })
      .catch(() => {
        //  TODO: Handle other errors: add a logging service and log the error
        // const knownErrors = [401];
        // if (knownErrors.includes(error.status)) {
        //   if (
        //     error.error.error['.tag'] &&
        //     error.error.error['.tag'] === 'invalid_access_token'
        //   ) {
        //     log.warn(
        //       'getAccountDetails - Token Invalid, will force reauth after next call'
        //     );
        //   }
        // }
        //  TODO: Handle other errors: add a logging service and log the error
        return null;
      });
  }
}

export const getStormcrows = async (client: Dropbox, features: string[]) => {
  try {
    const response = await client.stormcrowServicerGetAssignments({
      features: features.map((feature) => ({feature})),
    });

    if (!response.result.assignments) {
      return {};
    }
    return Object.fromEntries(
      response.result.assignments.map((assignment) => [
        assignment.feature,
        assignment.variant,
      ])
    );
  } catch (error: any) {
    return {};
  }
};
