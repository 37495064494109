import {Text, Title} from '@dropbox/dig-components/typography';
import React from 'react';

import styles from './NonIdealState.module.css';

interface NonIdealStateProps {
  image?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
}

export const NonIdealState = ({
  image,
  title,
  description,
  action,
}: NonIdealStateProps) => {
  return (
    <div className={styles.error}>
      {image && <div className={styles.image}>{image}</div>}
      {title && <Title className={styles.title}>{title}</Title>}
      {description && (
        <Text color="faint" size="large" className={styles.description}>
          {description}
        </Text>
      )}
      {action && action}
    </div>
  );
};
