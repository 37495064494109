import {RequireToken} from 'components/auth/Auth';
import {ROUTE_PATHS} from 'constant';
import {CareerFrameworks} from 'pages/career_frameworks';
import {ErrorPage} from 'pages/ErrorPage';
import {FeedbackV2} from 'pages/feedback_v2';
import {ManagerFeedback} from 'pages/manager_feedback';
import {ProfilePage} from 'pages/profile';
import {Waitlist} from 'pages/Waitlist';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from 'react-router-dom';
import {Layout} from 'views/Layout';

import {Login} from './pages/Login';
import {Logout} from './pages/Logout';
import {NotFound} from './pages/NotFound';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={<Navigate to={ROUTE_PATHS.FEEDBACK} replace />}
      ></Route>

      <Route path="/">
        <Route
          path={ROUTE_PATHS.FEEDBACK}
          element={
            <RequireToken>
              <Layout>
                <FeedbackV2 />
              </Layout>
            </RequireToken>
          }
          ErrorBoundary={ErrorPage}
        />
        <Route
          path={ROUTE_PATHS.MANAGER_FEEDBACK}
          element={
            <RequireToken>
              <Layout>
                <ManagerFeedback />
              </Layout>
            </RequireToken>
          }
          ErrorBoundary={ErrorPage}
        />
        <Route
          path={ROUTE_PATHS.CAREER_FRAMEWORK}
          element={
            <RequireToken>
              <Layout>
                <CareerFrameworks />
              </Layout>
            </RequireToken>
          }
          ErrorBoundary={ErrorPage}
        />
        <Route
          path={ROUTE_PATHS.PROFILE}
          element={
            <RequireToken>
              <Layout>
                <ProfilePage />
              </Layout>
            </RequireToken>
          }
          ErrorBoundary={ErrorPage}
        />
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path={ROUTE_PATHS.WAITLIST} element={<Waitlist />}></Route>
      <Route path="*" element={<NotFound />} />
    </>
  ),
  {basename: '/'}
);
