import {CareerFrameworkController} from 'components/career_frameworks/career_framework_controller';
import {Helmet} from 'react-helmet';

export const CareerFrameworks = () => {
  return (
    <>
      <Helmet>
        <title>Career Frameworks - SPRiTEs-GPT</title>
      </Helmet>
      <CareerFrameworkController />
    </>
  );
};
