import {Accordion} from '@dropbox/dig-components/accordion';
import {IconButton} from '@dropbox/dig-components/buttons';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {UIIcon} from '@dropbox/dig-icons';
import {PersonCircleLine, SearchLine} from '@dropbox/dig-icons/assets';
import {CareerFrameworkMetadata, Dropboxer as DropboxerResponse} from 'client';
import styles from 'components/dropboxer_search/dropboxer_search.module.css';
import {useThrottleAsyncCallback} from 'hooks/use_throttle';
import React from 'react';
import {DropboxerId, IndividualId} from 'store/features/feedback_chat/types';
import {getCareerFrameworksService, getDropboxService} from 'utilities';

export type {DropboxerResponse};

const CollapsibleContainer = ({
  children,
  leftAccessory,
  subtitle,
  title,
  isCollapsed,
  onHeaderClick,
}: {
  children: React.ReactNode;
  leftAccessory?: React.ReactNode;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  isCollapsed: boolean;
  onHeaderClick?: () => void;
}) => {
  return (
    <Accordion variant="standard" expandedPanels={isCollapsed ? [] : ['item']}>
      <Accordion.Item itemId="item">
        <Accordion.Header onClick={onHeaderClick} style={{border: 'none'}}>
          <Accordion.HeaderContent
            withLeftAccessory={leftAccessory}
            withSubtitle={subtitle}
            withTitle={title}
          />
        </Accordion.Header>
        <Accordion.Panel className={styles['accordion-panel']}>
          {children}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

/**
 * Props for searching for Dropboxers by name or email.
 * @param dropboxers A map of Dropboxer IDs to Dropboxer objects.
 * @param onSearchResultsChange A callback that is called when the search results change.
 * @param onSelectDropboxer A callback that is called when a Dropboxer is selected.
 * @param selectedDropboxerId The ID of the selected Dropboxer.
 * @param limit The maximum number of search results to return.
 */
type DropboxerSearchBarProps = {
  children?: React.ReactNode;
  onSearchResultsChange: (dropboxers: DropboxerResponse[]) => void;
  onSelectDropboxer: (dropboxerId?: DropboxerId) => void;
  selectedDropboxerId?: string;
  searchQuery?: string;
  setSearchQuery?: (query: string) => void;
  limit?: number;
};

/**
 * Props for searching for Dropboxers by name or email, or for Career Frameworks.
 * @param dropboxers A map of Dropboxer IDs to Dropboxer objects.
 * @param onSearchResultsChange A callback that is called when the search results change.
 * @param onSelectIndividual A callback that is called when an individual is selected.
 * @param selectedIndividualId The ID of the selected Individual.
 * @param limit The maximum number of search results to return.
 */
type DropboxerOrCareerFrameworkSearchBarProps = {
  children?: React.ReactNode;
  onSearchResultsChange: (
    dropboxers: DropboxerResponse[],
    careerFrameworkIndividuals: CareerFrameworkMetadata[]
  ) => void;
  onSelectIndividual: (individualId?: IndividualId) => void;
  selectedIndividualId?: string;
  searchQuery?: string;
  setSearchQuery?: (query: string) => void;
  limit?: number;
};

/**
 * A search bar for searching for Dropboxers by name or email.
 * State is managed by the parent component.
 */
export const DropboxerSearchBar = ({
  children,
  onSearchResultsChange,
  onSelectDropboxer,
  selectedDropboxerId,
  searchQuery,
  setSearchQuery,
  limit,
}: DropboxerSearchBarProps) => {
  const handleSearch = React.useCallback(
    async (query: string) => {
      if (query.length < 3) {
        return;
      }

      try {
        const response =
          await getDropboxService().searchDropboxerByNameOrEmailApiV1DropboxerSearchGet(
            query,
            limit ?? 3
          );

        if (
          response.dropboxers.every(
            (dropboxer) => dropboxer.employee_id !== selectedDropboxerId
          )
        ) {
          // If the selected dropboxer is not in the search results, unselect them
          onSelectDropboxer(undefined);
        }

        onSearchResultsChange(response.dropboxers);
      } catch (e) {
        console.error(e);
      }
    },
    [limit, onSearchResultsChange, onSelectDropboxer, selectedDropboxerId]
  );

  const handleSearchThrottled = useThrottleAsyncCallback(handleSearch, 1000);

  return (
    <div className={styles['search-container']}>
      <TextInput
        placeholder={'Search for Dropboxer...'}
        size="medium"
        withRightAccessory={
          <IconButton variant="borderless" size="large" shape="circular">
            <UIIcon src={SearchLine} />
          </IconButton>
        }
        onChange={(e) => {
          const query = e.target.value;
          setSearchQuery?.(query);
          if (query.length < 3) {
            return;
          }
          handleSearchThrottled(query);
        }}
        value={searchQuery}
      />
      {children}
    </div>
  );
};

/**
 * A search bar for searching for Dropboxers by name or email or Career Frameworks.
 * State is managed by the parent component.
 */
export const DropboxerOrCareerFrameworkSearchBar = ({
  children,
  onSearchResultsChange,
  onSelectIndividual,
  selectedIndividualId,
  searchQuery,
  setSearchQuery,
  limit,
}: DropboxerOrCareerFrameworkSearchBarProps) => {
  const handleSearch = React.useCallback(
    async (query: string) => {
      if (query.length < 3) {
        return;
      }

      try {
        const responseDropboxerSearch =
          await getDropboxService().searchDropboxerByNameOrEmailApiV1DropboxerSearchGet(
            query,
            limit ?? 3
          );
        const responseCareerFrameworksSearch =
          await getCareerFrameworksService().searchCareerFrameworksApiV1CareerFrameworkSearchGet(
            query,
            limit ?? 8,
            '(ic\\d)|(m\\d)'
          );

        if (
          responseDropboxerSearch.dropboxers.every(
            (dropboxer) => dropboxer.employee_id !== selectedIndividualId
          ) &&
          responseCareerFrameworksSearch.career_frameworks.every(
            (careerFrameworkIndividual) =>
              careerFrameworkIndividual.cf_id !== selectedIndividualId
          )
        ) {
          // If the selected individual is not in the search results, unselect them
          onSelectIndividual(undefined);
        }

        onSearchResultsChange(
          responseDropboxerSearch.dropboxers,
          responseCareerFrameworksSearch.career_frameworks
        );
      } catch (e) {
        console.error(e);
      }
    },
    [limit, onSearchResultsChange, onSelectIndividual, selectedIndividualId]
  );

  const handleSearchThrottled = useThrottleAsyncCallback(handleSearch, 1000);

  return (
    <div className={styles['search-container']}>
      <TextInput
        placeholder={'Search for Dropboxer or Career Framework...'}
        size="medium"
        withRightAccessory={
          <IconButton variant="borderless" size="large" shape="circular">
            <UIIcon src={SearchLine} />
          </IconButton>
        }
        onChange={(e) => {
          const query = e.target.value;
          setSearchQuery?.(query);
          if (query.length < 3) {
            return;
          }
          handleSearchThrottled(query);
        }}
        value={searchQuery}
      />
      {children}
    </div>
  );
};

export const CollapsibleDropboxerSearchBar = ({
  children,
  onSearchResultsChange,
  onSelectDropboxer,
  selectedDropboxerId,
  searchQuery,
  setSearchQuery,
  title,
}: DropboxerSearchBarProps & {title: string}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleIsCollapsed = React.useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const handleSelectDropboxer = React.useCallback(
    (dropboxerId?: DropboxerId) => {
      onSelectDropboxer(dropboxerId);
    },
    [onSelectDropboxer]
  );

  return (
    <CollapsibleContainer
      leftAccessory={<UIIcon src={PersonCircleLine} />}
      title={title}
      isCollapsed={isCollapsed}
      onHeaderClick={toggleIsCollapsed}
    >
      <DropboxerSearchBar
        onSearchResultsChange={onSearchResultsChange}
        onSelectDropboxer={handleSelectDropboxer}
        selectedDropboxerId={selectedDropboxerId}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      >
        {children}
      </DropboxerSearchBar>
    </CollapsibleContainer>
  );
};

export const CollapsibleDropboxerOrCareerFrameworkSearchBar = ({
  children,
  onSearchResultsChange,
  onSelectIndividual,
  selectedIndividualId,
  searchQuery,
  setSearchQuery,
  title,
}: DropboxerOrCareerFrameworkSearchBarProps & {title: string}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleIsCollapsed = React.useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const handleSelectIndividual = React.useCallback(
    (individualId?: IndividualId) => {
      onSelectIndividual(individualId);
    },
    [onSelectIndividual]
  );

  return (
    <CollapsibleContainer
      leftAccessory={<UIIcon src={PersonCircleLine} />}
      title={title}
      isCollapsed={isCollapsed}
      onHeaderClick={toggleIsCollapsed}
    >
      <DropboxerOrCareerFrameworkSearchBar
        onSearchResultsChange={onSearchResultsChange}
        onSelectIndividual={handleSelectIndividual}
        selectedIndividualId={selectedIndividualId}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      >
        {children}
      </DropboxerOrCareerFrameworkSearchBar>
    </CollapsibleContainer>
  );
};
