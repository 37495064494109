/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Feature opt-out types enumeration
 */
export enum OptOut {
    ALL = 'all',
    MANAGER_ASSISTED_FEEDBACK = 'manager_assisted_feedback',
    STAKEHOLDER_FEEDBACK = 'stakeholder_feedback',
}
