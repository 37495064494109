import {TrafficRoadBlockedSpot} from '@dropbox/dig-illustrations';
import {NonIdealState} from 'components/shared/NonIdealState';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

const ErrorPage = () => {
  const {t} = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (isRouteErrorResponse(error) && error.status === 403) {
      navigate('/login');
      return;
    }
  }, [error, navigate]);

  return (
    <NonIdealState
      image={<TrafficRoadBlockedSpot />}
      title={t('error_page_title')}
      description={t('check_the_url_and_try_again')}
    />
  );
};

export {ErrorPage};
