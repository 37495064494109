import {Accordion} from '@dropbox/dig-components/accordion';
import {IconButton} from '@dropbox/dig-components/buttons';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {Text} from '@dropbox/dig-components/typography';
import {UIIcon} from '@dropbox/dig-icons';
import {SearchLine} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import cx from 'classnames';
import {ReportsResponse} from 'client';
import {
  DropboxerResponse,
  DropboxerSearchBar,
} from 'components/dropboxer_search/dropboxer_search';
import {PeopleView} from 'components/dropboxer_search/people_view';
import {useAuthUserIsAdmin} from 'hooks/use_is_admin';
import {atom, useAtom} from 'jotai';
import React from 'react';
import {DropboxerId, Dropboxers} from 'store/features/feedback_chat/types';
import {getDropboxService} from 'utilities';

import styles from './manager_feedback.module.css';
import {SpritesCycleSelector} from './sprites_cycle_selector';

const selectedManagerAtom = atom<DropboxerId | undefined>(undefined);
const managerSearchQueryAtom = atom<string>('');
const managersAtom = atom<Dropboxers>({});

export const useGetReports = (managerId?: DropboxerId) => {
  const {data, status, error} = useQuery({
    queryKey: ['/dropboxer/get_reports', managerId],
    queryFn: () =>
      getDropboxService().getReportsApiV1DropboxerGetReportsGet(managerId),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  if (status === 'loading') {
    return {status};
  }

  if (status === 'error') {
    return {status, error};
  }

  const directReports =
    data.direct_reports.reduce((acc, dropboxer) => {
      return {...acc, [dropboxer.employee_id]: dropboxer};
    }, {} as Dropboxers) ?? ({} as Dropboxers);

  const secondaryReports =
    data.secondary_reports.reduce((acc, dropboxer) => {
      return {...acc, [dropboxer.employee_id]: dropboxer};
    }, {} as Dropboxers) ?? ({} as Dropboxers);

  const indirectReports =
    data.indirect_reports.reduce((acc, dropboxer) => {
      return {...acc, [dropboxer.employee_id]: dropboxer};
    }, {} as Dropboxers) ?? ({} as Dropboxers);

  const allDirectReportsIds = data.direct_reports.map(
    (dropboxer) => dropboxer.employee_id
  );

  const allSecondaryReportsIds = data.secondary_reports.map(
    (dropboxer) => dropboxer.employee_id
  );

  const allIndirectReportsIds = data.indirect_reports.map(
    (dropboxer) => dropboxer.employee_id
  );

  const allReportsIds = [
    ...allDirectReportsIds,
    ...allSecondaryReportsIds,
    ...allIndirectReportsIds,
  ];

  return {
    status,
    directReports,
    secondaryReports,
    indirectReports,
    allReportsIds,
  };
};

const useManagerSelector = () => {
  const [searchQuery, setSearchQuery] = useAtom(managerSearchQueryAtom);
  const [managers, setManagers] = useAtom(managersAtom);
  const [selectedManagerId, setSelectedManagerId] =
    useAtom(selectedManagerAtom);

  const handleSetSearchQuery = React.useCallback(
    (query: string) => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  const handleSearchResultChange = React.useCallback(
    (dropboxersResponse: DropboxerResponse[]) => {
      const dropboxers = dropboxersResponse.reduce(
        (acc, dropboxer) => ({
          ...acc,
          [dropboxer.employee_id]: dropboxer,
        }),
        {} as Dropboxers
      );

      setManagers(dropboxers);
    },
    [setManagers]
  );

  const handleSelectManagerId = React.useCallback(
    (dropboxerId?: DropboxerId) => {
      setSelectedManagerId(dropboxerId);
    },
    [setSelectedManagerId]
  );

  return {
    handleSearchResultChange,
    handleSelectManagerId,
    managers,
    searchQuery,
    selectedManagerId,
    setSearchQuery: handleSetSearchQuery,
  };
};

const ManagerSelector = () => {
  const {
    handleSearchResultChange,
    handleSelectManagerId,
    managers,
    searchQuery,
    selectedManagerId,
    setSearchQuery: handleSetSearchQuery,
  } = useManagerSelector();

  return (
    <DropboxerSearchBar
      onSearchResultsChange={handleSearchResultChange}
      onSelectDropboxer={handleSelectManagerId}
      selectedDropboxerId={selectedManagerId}
      searchQuery={searchQuery}
      setSearchQuery={handleSetSearchQuery}
    >
      <PeopleView
        dropboxers={managers}
        selectedDropboxerId={selectedManagerId}
        onSelectDropboxer={handleSelectManagerId}
      />
    </DropboxerSearchBar>
  );
};

type DropboxerSelectorProps = {
  dropboxers: Dropboxers;
  onSelectDropboxer: (dropboxerId?: DropboxerId) => void;
  selectedDropboxerId?: string;
};

const DropboxerSelector = ({
  dropboxers,
  onSelectDropboxer,
  selectedDropboxerId,
}: DropboxerSelectorProps) => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const dropboxerIds = Object.keys(dropboxers);

  const searchQueries = searchQuery.toLocaleLowerCase().split(' ');

  const filteredDropboxerIds =
    searchQuery !== ''
      ? dropboxerIds.filter((dropboxerId) => {
          const dropboxer = dropboxers[dropboxerId];

          return searchQueries.every(
            (query) =>
              dropboxer.preferred_first_name
                .toLocaleLowerCase()
                .includes(query) ||
              dropboxer.preferred_last_name
                .toLocaleLowerCase()
                .includes(query) ||
              dropboxer.email.toLocaleLowerCase().includes(query)
          );
        })
      : dropboxerIds;

  const filteredDropboxers = filteredDropboxerIds.reduce(
    (acc, dropboxerId) => ({
      ...acc,
      [dropboxerId]: dropboxers[dropboxerId],
    }),
    {} as Dropboxers
  );

  return (
    <div style={{display: 'flex', flexDirection: 'column', padding: '5px'}}>
      <TextInput
        placeholder={'Search for a Dropboxer...'}
        size="medium"
        withRightAccessory={
          <IconButton variant="borderless" size="large" shape="circular">
            <UIIcon src={SearchLine} />
          </IconButton>
        }
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
        value={searchQuery}
      />
      <PeopleView
        dropboxers={filteredDropboxers}
        selectedDropboxerId={selectedDropboxerId}
        onSelectDropboxer={onSelectDropboxer}
      />
    </div>
  );
};

type FeedbackFiltersProps = {
  selectedDropboxerId?: DropboxerId;
  onSelectDropboxer: (dropboxerId?: DropboxerId) => void;
};

type ReportsType = keyof ReportsResponse;

type FilterPanels = ReportsType | 'manager_selector' | 'sprites_cycle_selector';

type ReportsPanels = {
  [key in ReportsType]: {title: string};
};

const REPORTS_PANELS: ReportsPanels = {
  direct_reports: {title: 'Direct Reports'},
  secondary_reports: {title: 'Secondary Reports'},
  indirect_reports: {title: 'Indirect Reports'},
};

const REPORTS_PANELS_ORDER: ReportsType[] = [
  'direct_reports',
  'indirect_reports',
];
const ADMIN_REPORTS_PANELS_ORDER: ReportsType[] = [
  'direct_reports',
  'indirect_reports',
  'secondary_reports',
];

export const FeedbackFilters = ({
  selectedDropboxerId,
  onSelectDropboxer,
}: FeedbackFiltersProps) => {
  const [visiblePanel, setVisiblePanel] =
    React.useState<FilterPanels>('direct_reports');

  const onHeaderClick = (panelId: FilterPanels) => {
    setVisiblePanel(panelId);
  };

  const isAdmin = useAuthUserIsAdmin();
  const reportsPanelsOrder = isAdmin
    ? ADMIN_REPORTS_PANELS_ORDER
    : REPORTS_PANELS_ORDER;

  const {selectedManagerId} = useManagerSelector();
  const {
    allReportsIds,
    directReports,
    secondaryReports,
    indirectReports,
    status,
  } = useGetReports(selectedManagerId);

  const dropboxers: Record<keyof ReportsResponse, Dropboxers> = {
    direct_reports: directReports ?? {},
    secondary_reports: secondaryReports ?? {},
    indirect_reports: indirectReports ?? {},
  };

  React.useEffect(() => {
    // If the selected dropboxer is not in the list of reports, unselect it
    if (
      selectedDropboxerId &&
      status === 'success' &&
      !allReportsIds.includes(selectedDropboxerId)
    ) {
      onSelectDropboxer();
    }

    if (status === 'error' && selectedDropboxerId) {
      onSelectDropboxer();
    }
  }, [allReportsIds, onSelectDropboxer, selectedDropboxerId, status]);

  return (
    <div className={cx(styles['filters-container'])}>
      <Accordion variant="blades" expandedPanel={visiblePanel}>
        {isAdmin && (
          <Accordion.Item itemId="manager_selector" key="manager_selector">
            <Accordion.Header
              size="large"
              onClick={() => onHeaderClick('manager_selector')}
            >
              <Accordion.HeaderContent withTitle="ADMIN - Manager Selector" />
            </Accordion.Header>
            <Accordion.Panel>
              <ManagerSelector />
            </Accordion.Panel>
          </Accordion.Item>
        )}
        {reportsPanelsOrder.map((panelId) => {
          const {title} = REPORTS_PANELS[panelId];
          const isManagerSelected = selectedManagerId !== undefined;
          const isLoading = status === 'loading' && isManagerSelected;
          const isError = status === 'error';
          const isSuccess = status === 'success';

          return (
            <Accordion.Item itemId={panelId} key={panelId}>
              <Accordion.Header
                size="large"
                onClick={() => onHeaderClick(panelId)}
              >
                <Accordion.HeaderContent withTitle={title} />
              </Accordion.Header>
              <Accordion.Panel>
                {isLoading && <Spinner size="medium" />}
                {isError && <Text>Error loading reports</Text>}
                {isSuccess && (
                  <DropboxerSelector
                    dropboxers={dropboxers[panelId]}
                    onSelectDropboxer={onSelectDropboxer}
                    selectedDropboxerId={selectedDropboxerId}
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
        {selectedDropboxerId && (
          <Accordion.Item
            itemId="sprites_cycle_selector"
            key="sprites_cycle_selector"
          >
            <Accordion.Header
              size="large"
              onClick={() => onHeaderClick('sprites_cycle_selector')}
            >
              <Accordion.HeaderContent withTitle="SPRiTEs Cycle" />
            </Accordion.Header>
            <Accordion.Panel>
              <SpritesCycleSelector dropboxerId={selectedDropboxerId} />
            </Accordion.Panel>
          </Accordion.Item>
        )}
      </Accordion>
    </div>
  );
};
