import {Text} from '@dropbox/dig-components/typography';
import cx from 'classnames';
import DomPurify from 'dompurify';

import styles from './manager_feedback.module.css';

export const TextBubble = ({html, text}: {html?: string; text?: string}) => {
  const purifiedHtml =
    html &&
    DomPurify.sanitize(html, {
      ALLOWED_TAGS: [
        'p',
        'span',
        'ul',
        'li',
        'strong',
        'em',
        'div',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
      ],
    });
  return (
    <Text
      variant="paragraph"
      tagName="p"
      className={cx(styles['bubble-text'])}
      dangerouslySetInnerHTML={
        purifiedHtml
          ? {
              __html: purifiedHtml,
            }
          : undefined
      }
    >
      {text}
    </Text>
  );
};
