import {Avatar} from '@dropbox/dig-components/avatar';
import {Text} from '@dropbox/dig-components/typography';
import {
  getDropboxerFullName,
  getDropboxerInitials,
} from 'components/manager_feedback/dropboxer_name_utils';
import {Dropboxer} from 'store/features/feedback_chat/types';

import styles from './dropboxer_card.module.css';

export const DropboxerCard = ({dropboxer}: {dropboxer: Dropboxer}) => (
  <div className={styles['label-group']}>
    <Avatar hasNoOutline className={styles['label-avatar']}>
      {getDropboxerInitials(dropboxer)}
    </Avatar>
    <div className={styles['label-text']}>
      <Text variant="label" tagName="span">
        {getDropboxerFullName(dropboxer)}
        <br />
      </Text>
      <Text
        variant="label"
        tagName="span"
        color="faint"
      >{`${dropboxer.job_title} - ${dropboxer.job_level}`}</Text>
    </div>
  </div>
);
