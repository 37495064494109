import {configureStore} from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import {authReducer} from 'store/features/auth';
import {feedbackChatReducer} from 'store/features/feedback_chat/slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    feedbackChat: feedbackChatReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunkMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
