import styles from './Main.module.css';

type LayoutProps = {
  children?: React.ReactNode;
};

export const Main = ({children}: LayoutProps) => {
  return (
    <>
      <div className={styles.container} id="mainContainer">
        {children}
      </div>
    </>
  );
};
