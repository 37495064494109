import {Button} from '@dropbox/dig-components/buttons';
import {Checkbox} from '@dropbox/dig-components/controls';
import {Modal} from '@dropbox/dig-components/modal';
import {Link, Text} from '@dropbox/dig-components/typography';
import {useQuery} from '@tanstack/react-query';
import {ApiError, DisclaimerType} from 'client';
import styles from 'components/disclaimer_modal/disclaimer_modal.module.css';
import React from 'react';
import {getAuthService} from 'utilities';

const AI_USE_POLICY_HREF =
  'https://dropboxprod.service-now.com/dbx?id=kb_article_view&sysparm_article=KB0012244';

const GLOBAL_EMPLOYEE_PRIVACY_NOTICE_HREF =
  'https://dropboxprod.service-now.com/dbx?sys_kb_id=f897bf331b317d9082077661cd4bcb92&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=f850502197f6b19091befd76f053af23';

const DISCLAIMER_LAST_UPDATE: Record<DisclaimerType, string> = {
  [DisclaimerType.STAKEHOLDER_FEEDBACK]: '2024-05-29T00:00:00',
  [DisclaimerType.MANAGER_ASSISTANT]: '2024-05-29T00:00:00',
};

const getDisclaimer = async (disclaimerType: DisclaimerType) => {
  const response = await getAuthService().getDisclaimersApiV1DisclaimersGet([
    disclaimerType,
  ]);

  for (const disclaimer of response.disclaimers) {
    if (disclaimer.type === disclaimerType) {
      return disclaimer;
    }
  }

  return null;
};

/**
 * Fetches the disclaimer status and opens the disclaimer modal if necessary.
 */
const useGetDisclaimerStatus = (disclaimerType: DisclaimerType) => {
  const {data, status, error, refetch, isRefetching} = useQuery(
    ['/api/v1/disclaimers', disclaimerType],
    () => getDisclaimer(disclaimerType),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  );

  if (status === 'loading') {
    return {
      queryStatus: status,
      sholdShowDisclaimer: false,
      isRefetching,
      refetch,
    };
  }

  if (status === 'error') {
    if (error instanceof ApiError && [401].includes(error.status)) {
      return {
        queryStatus: status,
        sholdShowDisclaimer: false,
        isRefetching,
        refetch,
      };
    }
    return {
      queryStatus: status,
      sholdShowDisclaimer: true,
      isRefetching,
      refetch,
    };
  }

  const disclaimerAcceptanceExpired =
    !!data &&
    Date.now() - Date.parse(data.accepted_at) > 150 * 24 * 60 * 60 * 1000; // 150 days, ~5 months

  const disclaimerAcceptedBeforeUpdate =
    !!data &&
    Date.parse(data.accepted_at) <
      Date.parse(DISCLAIMER_LAST_UPDATE[disclaimerType]);

  const sholdShowDisclaimer =
    data?.type !== disclaimerType ||
    disclaimerAcceptanceExpired ||
    disclaimerAcceptedBeforeUpdate;

  return {
    queryStatus: status,
    sholdShowDisclaimer,
    isRefetching,
    refetch,
  };
};

type DisclaimerModalProps = {
  disclaimerType: DisclaimerType;
};

export const DisclaimerModal = ({disclaimerType}: DisclaimerModalProps) => {
  const {
    sholdShowDisclaimer: isOpen,
    refetch,
    isRefetching,
  } = useGetDisclaimerStatus(disclaimerType);

  const [isCheckboxChecked, setIsCheckboxChecked] = React.useState(false);

  const handleCheckboxClick = () => {
    setIsCheckboxChecked((prev) => !prev);
  };

  const handleAcceptClick = async () => {
    if (isCheckboxChecked) {
      await getAuthService().setDisclaimerApiV1AcceptDisclaimerPost(
        disclaimerType
      );

      refetch();
    }
  };

  return (
    <Modal open={isOpen}>
      <Modal.Header>
        <Modal.Title>Disclaimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {disclaimerType === DisclaimerType.STAKEHOLDER_FEEDBACK && (
          <StakeHolderDisclaimer />
        )}
        {disclaimerType === DisclaimerType.MANAGER_ASSISTANT && (
          <ManagerDisclaimer />
        )}
        <div className={styles['checkbox']}>
          <Checkbox
            id={`stakeholder-accept-checkbox`}
            checked={isCheckboxChecked}
            onClick={handleCheckboxClick}
          />
          <Text
            tagName="label"
            className="checkboxLabel"
            htmlFor={`stakeholder-accept-checkbox`}
          >
            I Agree
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer className={styles['modal-footer']}>
        <Button
          variant="primary"
          disabled={!isCheckboxChecked}
          isLoading={isRefetching}
          onClick={handleAcceptClick}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const StakeHolderDisclaimer = () => (
  <>
    <Text variant="paragraph" tagName="p">
      SPRiTEs-GPT is a writing aid designed to help with feedback creation using
      our Core Responsibilities. It is not intended to replace a thoughtful
      assessment of an employee’s performance. You bear full responsibility for
      making all necessary revisions/additions to provide a personalized and
      accurate version before submitting your review in Workday.
    </Text>
    <Text variant="paragraph" tagName="p">
      Dropbox provided input will not be used to train OpenAI’s models.
      Dropboxers must adhere to our{' '}
      <Link href={AI_USE_POLICY_HREF}>AI Acceptable Use Policy</Link>, and avoid
      including non-public information, such as confidential or financial
      details. See also our{' '}
      <Link href={GLOBAL_EMPLOYEE_PRIVACY_NOTICE_HREF}>
        Global Employee Privacy Notice
      </Link>
      .
    </Text>
    <Text variant="paragraph" tagName="p">
      Dropboxers can choose to opt out of having stakeholders use the writing
      assistant for them. If they opt out, you will get an error message.
    </Text>
    <Text variant="paragraph" tagName="p">
      By checking here, you agree to follow our{' '}
      <Link href={AI_USE_POLICY_HREF}>AI Acceptable Use Policy</Link>,
      understand your responsibility for thoughtfully assessing an employee’s
      strengths and growth areas, and only submitting feedback you agree with in
      Workday. You also understand that you can opt out.
    </Text>
  </>
);

const ManagerDisclaimer = () => (
  <>
    <Text variant="paragraph" tagName="p">
      SPRiTEs-GPT, the manager assistant, is currently a pilot in NAMER. The
      tool presents all feedback received for direct reports in a more readable
      format and categorized by Core Responsibility, providing a summary based
      on AI-generated themes.
    </Text>
    <Text variant="paragraph" tagName="p">
      <strong>
        It is critical that managers do not make decisions using the
        AI-generated summary.{' '}
      </strong>
      AI tools are one input, lack context, and can make mistakes.{' '}
      <strong>
        Managers bear full responsibility for reading all feedback, considering
        their own observations, and creating a thoughtful summary as part of the
        review.
      </strong>
    </Text>
    <Text variant="paragraph" tagName="p">
      Since this is a NAMER pilot, employees outside NAMER will show an error
      message when searched. Dropboxers can also choose to opt out of having
      managers use the writing assistant for them.
    </Text>
    <Text variant="paragraph" tagName="p">
      Dropbox provided input will not be used to train OpenAI’s models. Managers
      must adhere to our{' '}
      <Link href={AI_USE_POLICY_HREF}>AI Acceptable Use Policy</Link>, and avoid
      including non-public information, such as confidential or financial
      details. See also our{' '}
      <Link href={GLOBAL_EMPLOYEE_PRIVACY_NOTICE_HREF}>
        Global Employee Privacy Notice
      </Link>
      .
    </Text>
    <Text variant="paragraph" tagName="p">
      By checking here, you agree to follow our{' '}
      <Link href={AI_USE_POLICY_HREF}>AI Acceptable Use Policy</Link>,
      understand your responsibility for thoughtfully assessing an employee’s
      performance, and only submitting assessments you agree with in Workday.
      You also understand that you can opt out.
    </Text>
  </>
);
