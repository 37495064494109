import {DoorAwaySpot} from '@dropbox/dig-illustrations';
import {NonIdealState} from 'components/shared/NonIdealState';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

const Waitlist = () => {
  const {t} = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('sprites_gpt')}</title>
      </Helmet>
      <NonIdealState
        image={<DoorAwaySpot />}
        title={t('you_are_waitlist')}
        description={t('we_will_let_you_know_once_you_get_access')}
      />
    </>
  );
};

export {Waitlist};
