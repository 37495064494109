import {LeftNav} from 'components/layout/LeftNav';
import {Main} from 'components/layout/Main';
import {TopNavbar} from 'components/TopNavbar';
import {ROUTE_PATHS} from 'constant';
import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import styles from './Layout.module.css';

const Layout = ({children}: {children: React.ReactNode}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const shouldRedirectHome = location.pathname === '/';

  React.useEffect(() => {
    if (shouldRedirectHome) {
      // Redirect from root to /goals
      navigate(ROUTE_PATHS.FEEDBACK, {replace: true});
    }
  }, [navigate, shouldRedirectHome]);

  return (
    <div className={styles.wrapper}>
      <TopNavbar />
      <div className={styles.container}>
        <LeftNav />
        <Main>{children}</Main>
      </div>
    </div>
  );
};

export {Layout};
