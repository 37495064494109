import {Dropboxer} from 'store/features/feedback_chat/types';

export const getDropboxerFullName = (dropboxer: Dropboxer | string) => {
  if (typeof dropboxer === 'string') {
    return dropboxer;
  }

  return `${dropboxer.preferred_first_name} ${dropboxer.preferred_last_name}`;
};

export const getDropboxerInitials = (dropboxer: Dropboxer | string) => {
  if (typeof dropboxer === 'string') {
    const [firstName, lastName] = dropboxer.split(' ');
    return `${firstName[0]}${lastName[0]}`;
  }

  return `${dropboxer.preferred_first_name[0]}${dropboxer.preferred_last_name[0]}`;
};
