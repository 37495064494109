import {Spinner} from '@dropbox/dig-components/dist/progress_indicators';
import {Text, Title} from '@dropbox/dig-components/typography';
import {useQuery} from '@tanstack/react-query';
import {ApiError} from 'client';
import {CareerFramework} from 'components/career_frameworks/career_framework';
import styles from 'components/career_frameworks/career_framework_controller.module.css';
import {useQueryParamState} from 'hooks/use_query_params';
import {getCareerFrameworksService} from 'utilities';

import {CareerFrameworkBrowser} from './career_framework_browser';

export const useGetCareerFrameworkContent = (careerFrameworkId?: string) => {
  const {data, status, error} = useQuery(
    ['/api/v1/career_framework', careerFrameworkId],
    () =>
      getCareerFrameworksService().getCareerFrameworkFromIdApiV1CareerFrameworkCareerFrameworkIdGet(
        careerFrameworkId ?? ''
      ),
    {
      enabled: !!careerFrameworkId,
      staleTime: Infinity,
    }
  );

  if (!careerFrameworkId) {
    return {status: 'idle' as const};
  }

  if (status === 'loading') {
    return {status};
  }

  if (status === 'error') {
    return {status, error: error as ApiError};
  }

  return {status, content: data.content, name: data.name};
};

const TITLE_BY_STATUS = {
  idle: 'Select a career framework',
  loading: 'Loading...',
  error: 'Error',
};

export const CareerFrameworkController = () => {
  const [careerFrameworkId] = useQueryParamState('cf', {
    defaultValue: 'core_responsibilities',
  });
  const {content, name, status} =
    useGetCareerFrameworkContent(careerFrameworkId);

  const title = status === 'success' ? name : TITLE_BY_STATUS[status];

  return (
    <div className={styles['page']}>
      <div className={styles['career-frameworks-nav']}>
        <CareerFrameworkBrowser />
      </div>
      <div className={styles['career-framework']}>
        <div className={styles['career-framework-content']}>
          <Title
            tagName="h1"
            weightVariant="emphasized"
            size="large"
            className={styles['career-framework-label']}
          >
            {title}
          </Title>
          {status === 'loading' && <Spinner size="large" />}
          {status === 'error' && <Text>Failed to load career framework</Text>}
          {content && <CareerFramework markdown={content} />}
        </div>
      </div>
    </div>
  );
};
