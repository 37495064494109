import React from 'react';

/**
 * Throttle async function to prevent multiple calls in a short time.
 *
 * @param fn async function
 * @param throttleMs throttle time in ms
 * @returns throttled async function
 */
export const useThrottleAsyncCallback = <A extends any[], R>(
  fn: (...args: A) => Promise<R>,
  throttleMs: number
): typeof fn => {
  const lastInvokeTime = React.useRef(0);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  return React.useCallback(
    (...args: A) => {
      const deltaInvokeTime = Date.now() - lastInvokeTime.current;

      if (deltaInvokeTime < throttleMs) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        return new Promise<R>((resolve) => {
          timeoutRef.current = setTimeout(() => {
            lastInvokeTime.current = Date.now();
            resolve(fn(...args));
          }, deltaInvokeTime);
        });
      }

      lastInvokeTime.current = Date.now();
      return fn(...args);
    },
    [fn, throttleMs]
  );
};
