import {Button} from '@dropbox/dig-components/dist/buttons';
import {LookMagnifyingGlassSpot} from '@dropbox/dig-illustrations';
import {NonIdealState} from 'components/shared/NonIdealState';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const NotFound = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>
          {t('not_found')} - {t('sprites_gpt')}
        </title>
      </Helmet>
      <NonIdealState
        image={<LookMagnifyingGlassSpot />}
        title={t('we_cant_find_the_page_youre_looking_for')}
        description={t('check_the_url_and_try_again')}
        action={
          <Button
            href={'/'}
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              navigate('/');
            }}
          >
            {t('go_back_home')}
          </Button>
        }
      />
    </>
  );
};

export {NotFound};
