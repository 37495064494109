import {IconButton} from '@dropbox/dig-components/buttons';
import {LabelGroup} from '@dropbox/dig-components/combinations';
import {List} from '@dropbox/dig-components/list';
import {TextInput} from '@dropbox/dig-components/text_fields';
import {UIIcon} from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronRightLine,
  PaperDocLine,
  SearchLine,
} from '@dropbox/dig-icons/assets';
import {useQuery} from '@tanstack/react-query';
import {ApiError, CareerFrameworkMetadata, CareerFrameworkTree} from 'client';
import {useQueryParamState} from 'hooks/use_query_params';
import {useThrottleAsyncCallback} from 'hooks/use_throttle';
import {atom, useAtom} from 'jotai';
import React from 'react';
import {getCareerFrameworksService} from 'utilities';

import styles from './career_framework_controller.module.css';

const useSearchCareerFrameworkState = () => {
  const [searchQuery, setSearchQuery] = useQueryParamState('q');
  const [searchData, setSearchData] = React.useState<CareerFrameworkMetadata[]>(
    []
  );

  const handleSearchThrottled = useThrottleAsyncCallback(
    async (query: string) => {
      if (query.length < 3) {
        return;
      }

      const response =
        await getCareerFrameworksService().searchCareerFrameworksApiV1CareerFrameworkSearchGet(
          query,
          100
        );
      setSearchData(response.career_frameworks);
    },
    1000
  );

  const onSearchQueryChange = (query: string) => {
    setSearchQuery(query);
    handleSearchThrottled(query);
  };

  const treeData: CareerFrameworkTree = {
    name: 'Search Results',
    id: 'search_results',
    leafs: [],
    career_frameworks: searchData,
  };

  return {searchQuery, onSearchQueryChange, searchData: treeData};
};

const useCareerFrameworkTree = () => {
  const {data, status, error} = useQuery(
    ['/api/v1/career_frameworks'],
    getCareerFrameworksService()
      .getCareerFrameworksStructureApiV1CareerFrameworksGet,
    {
      staleTime: Infinity,
    }
  );

  if (status === 'loading') {
    return {status};
  }

  if (status === 'error') {
    return {status, error: error as ApiError};
  }

  return {status, data};
};

export const CareerFrameworkBrowser = () => {
  const {data: treeData} = useCareerFrameworkTree();
  const {searchQuery, onSearchQueryChange, searchData} =
    useSearchCareerFrameworkState();

  const data = searchQuery && searchQuery.length > 2 ? searchData : treeData;

  return (
    <div className={styles['career-frameworks-browser']}>
      <TextInput
        className={styles.searchBar}
        placeholder={'Search for a Career Framework...'}
        size="medium"
        withRightAccessory={
          <IconButton variant="borderless" size="large" shape="circular">
            <UIIcon src={SearchLine} />
          </IconButton>
        }
        onChange={(e) => {
          const query = e.target.value;
          onSearchQueryChange(query);
        }}
        value={searchQuery ?? ''}
      />
      {data && (
        <List spacing="xsmall" paddingY={0}>
          <CareerFrameworkFolder folder={data} />
        </List>
      )}
    </div>
  );
};

type CareerFrameworkListProps = {
  folders: Array<CareerFrameworkTree>;
  docs: Array<CareerFrameworkMetadata>;
};

const CareerFrameworkList = ({folders, docs}: CareerFrameworkListProps) => {
  const sortedFolders = folders.sort((a, b) => a.name.localeCompare(b.name));
  const sortedDocs = docs.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <List
      spacing="xsmall"
      paddingY={0}
      paddingLeft={'var(--dig-spacing__micro__medium)'}
    >
      {sortedFolders.map((folder) => (
        <CareerFrameworkFolder key={folder.id} folder={folder} />
      ))}
      {sortedDocs.map((doc) => (
        <CareerFrameworkFile key={doc.cf_id} name={doc.name} id={doc.cf_id} />
      ))}
    </List>
  );
};
type CareerFrameworkFileProps = {
  name: string;
  id: string;
};
const CareerFrameworkFile = ({name, id}: CareerFrameworkFileProps) => {
  const [selectedCareerFramework, setSelectedCareerFramework] =
    useQueryParamState('cf');

  const isSelected = selectedCareerFramework === id;
  return (
    <List.Item
      isSelectable
      isSelected={isSelected}
      onClick={() => {
        setSelectedCareerFramework(id);
      }}
    >
      <List.Content>
        <LabelGroup
          withText={name}
          withLeftAccessory={<UIIcon src={PaperDocLine} />}
        />
      </List.Content>
    </List.Item>
  );
};

const expandedCareerFrameworkFoldersAtom = atom<string[]>([
  './career_frameworks',
  'search_results',
]);

type CareerFrameworkFolderProps = {
  folder: CareerFrameworkTree;
};

const CareerFrameworkFolder = ({folder}: CareerFrameworkFolderProps) => {
  const [expandedCareerFrameworkFolders, setExpandedCareerFrameworkFolders] =
    useAtom(expandedCareerFrameworkFoldersAtom);

  const isExpanded = expandedCareerFrameworkFolders.includes(folder.id);
  const toggleExpanded = () => {
    setExpandedCareerFrameworkFolders((prev) =>
      prev.includes(folder.id)
        ? prev.filter((id) => id !== folder.id)
        : [...prev, folder.id]
    );
  };
  return (
    <List.Item
      withSubList={
        isExpanded && (
          <CareerFrameworkList
            folders={folder.leafs}
            docs={folder.career_frameworks}
          />
        )
      }
    >
      <List.Accessory>
        <IconButton
          variant="borderless"
          aria-pressed="false"
          onClick={toggleExpanded}
        >
          <UIIcon
            src={isExpanded ? ChevronDownLine : ChevronRightLine}
            size="small"
          ></UIIcon>
        </IconButton>
      </List.Accessory>
      <List.Content>
        <LabelGroup withText={folder.name} />
      </List.Content>
    </List.Item>
  );
};
