import {Avatar} from '@dropbox/dig-components/avatar';
import {LabelGroup} from '@dropbox/dig-components/combinations';
import {List} from '@dropbox/dig-components/list';
import {atom, useAtom} from 'jotai';
import React from 'react';

import {useGetFeedback} from './feedback';

type SpritesCycleSelectorProps = {
  dropboxerId: string;
};

export const spritesCycleAtom = atom<string | null>(null);

const getSpritesYear = (cycle: string) => {
  return cycle.match(/\d{4}/g)?.[0];
};

const spritesCycleComparison = (cycleA: string, cycleB: string) => {
  const yearA = getSpritesYear(cycleA);
  const yearB = getSpritesYear(cycleB);
  if (!yearA || !yearB) {
    return 0;
  }

  if (yearA > yearB) {
    return -1;
  }

  if (yearA < yearB) {
    return 1;
  }

  // If the years are the same, we want to prioritize the non-diet cycle
  const isDietA = cycleA.toLowerCase().includes('diet');
  const isDietB = cycleB.toLowerCase().includes('diet');

  if (isDietA && !isDietB) {
    return 1;
  }

  if (!isDietA && isDietB) {
    return -1;
  }

  return 0;
};

const sortFeedbackCycles = (feedbackTypes: string[]) => {
  return feedbackTypes.sort(spritesCycleComparison);
};

const useFeedbackCycles = (dropboxerId: string) => {
  const {feedbackState} = useGetFeedback(dropboxerId);
  const [selectedSpritesCycle, setSelectedSpritesCycle] =
    useAtom(spritesCycleAtom);

  const spritesCycles = feedbackState?.allFeedbackIds.reduce(
    (acc: string[], feedbackId) => {
      const {type} = feedbackState.byFeedbackId[feedbackId];
      if (!acc.includes(type)) {
        acc.push(type);
      }
      return acc;
    },
    [] as string[]
  );

  const sortedSpritesCycles = sortFeedbackCycles(spritesCycles || []);

  React.useEffect(() => {
    if (sortedSpritesCycles.length === 0) {
      setSelectedSpritesCycle(null);
    }

    if (
      selectedSpritesCycle === null ||
      !sortedSpritesCycles.includes(selectedSpritesCycle)
    ) {
      setSelectedSpritesCycle(sortedSpritesCycles[0]);
    }
  }, [sortedSpritesCycles, selectedSpritesCycle, setSelectedSpritesCycle]);

  const handleSetSpritesCycle = (cycle: string) => {
    if (sortedSpritesCycles.includes(cycle)) {
      setSelectedSpritesCycle(cycle);
    }
  };

  return {sortedSpritesCycles, selectedSpritesCycle, handleSetSpritesCycle};
};

export const SpritesCycleSelector = ({
  dropboxerId,
}: SpritesCycleSelectorProps) => {
  const {sortedSpritesCycles, selectedSpritesCycle, handleSetSpritesCycle} =
    useFeedbackCycles(dropboxerId);

  return (
    <List isSelectable>
      {sortedSpritesCycles.map((cycle) => (
        <List.Item
          key={cycle}
          onClick={() => handleSetSpritesCycle(cycle)}
          isSelected={selectedSpritesCycle === cycle}
        >
          <List.Content>
            <LabelGroup
              withText={cycle}
              withLeftAccessory={
                <Avatar size="large">{getSpritesYear(cycle)?.slice(-2)}</Avatar>
              }
            />
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};
