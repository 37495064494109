import {IconButton} from '@dropbox/dig-components/buttons';
import {Menu} from '@dropbox/dig-components/dist/menu';
import {UIIcon} from '@dropbox/dig-icons';
import {EditLine} from '@dropbox/dig-icons/dist/mjs/assets';
import React from 'react';

import {
  CORE_RESPONSIBILITIES,
  CoreResponsibilityId,
  FEEDBACK_ACCORDION_TITLES,
  useSetFeedbackQuotesCallback,
} from './feedback_quotes';

const isCoreResponsibility = (value: string): value is CoreResponsibilityId =>
  CORE_RESPONSIBILITIES.includes(value as any);

const useUpdateQuoteCoreResponsibilityCallback = (dropboxerId: string) => {
  const setFeedbackQuotes = useSetFeedbackQuotesCallback(dropboxerId);

  return React.useCallback(
    ({
      quoteId,
      coreResponsibility,
    }: {
      quoteId: string;
      coreResponsibility: CoreResponsibilityId;
    }) => {
      setFeedbackQuotes((prevQuotes) => {
        if (!prevQuotes) {
          return prevQuotes;
        }

        const prevCoreResponsibility =
          Object.keys(prevQuotes.byCoreResponsibility).find((key) => {
            if (isCoreResponsibility(key)) {
              return prevQuotes.byCoreResponsibility[key].includes(quoteId);
            }
            return false;
          }) || '';

        if (!isCoreResponsibility(prevCoreResponsibility)) {
          return prevQuotes;
        }

        const newQuotes = {
          ...prevQuotes,
          byCoreResponsibility: {
            ...prevQuotes.byCoreResponsibility,
            [prevCoreResponsibility]: prevQuotes.byCoreResponsibility[
              prevCoreResponsibility
            ].filter((id) => id !== quoteId),
            [coreResponsibility]: [
              ...prevQuotes.byCoreResponsibility[coreResponsibility],
              quoteId,
            ],
          },
        };

        return newQuotes;
      });
    },
    [setFeedbackQuotes]
  );
};

export const FeedbackQuotesOptionMenu = ({
  quoteId,
  dropboxerId,
}: {
  quoteId: string;
  dropboxerId: string;
}) => {
  const handleUpdateQuoteCoreResponsibility =
    useUpdateQuoteCoreResponsibilityCallback(dropboxerId);

  const handleSelection = (value: string) => {
    if (!isCoreResponsibility(value)) {
      return;
    }

    handleUpdateQuoteCoreResponsibility({quoteId, coreResponsibility: value});
  };
  return (
    <Menu.Wrapper onSelection={handleSelection}>
      {({getContentProps, getTriggerProps}) => (
        <>
          <IconButton {...getTriggerProps()} variant="borderless" size="small">
            <UIIcon src={EditLine} />
          </IconButton>
          <Menu.Content {...getContentProps()}>
            {CORE_RESPONSIBILITIES.map((coreResponsibility) => (
              <Menu.ActionItem
                key={coreResponsibility}
                value={coreResponsibility}
              >
                {FEEDBACK_ACCORDION_TITLES[coreResponsibility]}
              </Menu.ActionItem>
            ))}
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
