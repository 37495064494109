import {Button} from '@dropbox/dig-components/buttons';
import {List} from '@dropbox/dig-components/list';
import {Spinner} from '@dropbox/dig-components/progress_indicators';
import {Text} from '@dropbox/dig-components/typography';
import {useQuery} from '@tanstack/react-query';
import cx from 'classnames';
import {useAtom} from 'jotai';
import {Dropboxer, DropboxerId} from 'store/features/feedback_chat/types';
import {getDropboxService} from 'utilities';

import {DropboxerCard} from './dropboxer_card';
import styles from './manager_feedback.module.css';
import {spritesCycleAtom} from './sprites_cycle_selector';
import {TextBubble} from './text_bubble';

type Feedback = {
  question: string;
  htmlAnswer: string;
  date: string;
  type: string;
  feedbackGiverId: DropboxerId;
};

export type FeedbackId = string;

type FeedbackState = {
  byFeedbackGiverId: {
    [feedbackGiverId: DropboxerId]: FeedbackId[];
  };
  byFeedbackId: {
    [feedbackId: FeedbackId]: Feedback;
  };
  allFeedbackIds: FeedbackId[];
};

const defaultFeedbackState: FeedbackState = {
  byFeedbackGiverId: {},
  byFeedbackId: {},
  allFeedbackIds: [],
};

type FeedbackGiversState = {
  byFeedbackGiverId: {
    [feedbackGiverId: DropboxerId]: Dropboxer;
  };
  allFeedbackGiverIds: DropboxerId[];
};

const defaultFeedbackGiversState: FeedbackGiversState = {
  byFeedbackGiverId: {},
  allFeedbackGiverIds: [],
};

const getFeedback = async (dropboxerId: DropboxerId) => {
  const feedbackResponse =
    await getDropboxService().getFeedbackApiV1DropboxerFeedbackEmployeeIdGet(
      dropboxerId
    );

  const feedbackState = feedbackResponse.feedback.reduce((prevState, fb) => {
    const feedbackGiverId = fb.feedback_giver_id;
    if (!feedbackGiverId) {
      return prevState;
    }

    const feedbackId = fb.id;

    return {
      ...prevState,
      byFeedbackGiverId: {
        ...prevState.byFeedbackGiverId,
        [feedbackGiverId]: [
          ...(prevState.byFeedbackGiverId[feedbackGiverId] || []),
          feedbackId,
        ],
      },
      byFeedbackId: {
        ...prevState.byFeedbackId,
        [feedbackId]: {
          question: fb.question,
          htmlAnswer: fb.answer,
          date: fb.date,
          type: fb.type,
          feedbackGiverId,
        },
      },
      allFeedbackIds: [...prevState.allFeedbackIds, feedbackId],
    };
  }, defaultFeedbackState);

  const feedbackGiversState = feedbackResponse.dropboxers.reduce(
    (prevState, dropboxer) => {
      return {
        ...prevState,
        byFeedbackGiverId: {
          ...prevState.byFeedbackGiverId,
          [dropboxer.employee_id]: dropboxer,
        },
        allFeedbackGiverIds: [
          ...prevState.allFeedbackGiverIds,
          dropboxer.employee_id,
        ],
      };
    },
    defaultFeedbackGiversState
  );

  return {feedbackState, feedbackGiversState};
};

export const useGetFeedback = (dropboxerId: DropboxerId) => {
  const {data, status, error, isRefetching, refetch} = useQuery({
    queryKey: ['/dropboxer/feedback', dropboxerId],
    queryFn: () => getFeedback(dropboxerId),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // 1 hour
  });

  if (status === 'loading') {
    return {
      status,
    };
  }

  if (status === 'error') {
    return {
      status,
      error,
      isRefetching,
      refetch,
    };
  }

  return {
    status,
    feedbackState: data.feedbackState,
    feedbackGiversState: data.feedbackGiversState,
  };
};

type FeedbackProps = {
  dropboxerId: DropboxerId;
};

export const FeedbackController = ({dropboxerId}: FeedbackProps) => {
  const {status, feedbackState, feedbackGiversState, isRefetching, refetch} =
    useGetFeedback(dropboxerId);

  const [spritesCycle] = useAtom(spritesCycleAtom);

  if (status === 'loading') {
    return (
      <div className={cx(styles['generic-container'])}>
        <Spinner size="large" />
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className={cx(styles['generic-container'])}>
        <Text variant="paragraph" tagName="p" size="xlarge">
          Error loading feedback.
        </Text>
        <Button
          variant="primary"
          onClick={() => refetch()}
          isLoading={isRefetching}
        >
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className={cx(styles['feedback-container'])}>
      <List>
        {feedbackGiversState.allFeedbackGiverIds.map((feedbackGiverId) => {
          const allReceivedFeedbackIds =
            feedbackState.byFeedbackGiverId[feedbackGiverId];

          const feedbackGiver =
            feedbackGiversState.byFeedbackGiverId[feedbackGiverId];

          return (
            <List.Item key={feedbackGiverId} spacing="xsmall">
              <List.Content>
                {allReceivedFeedbackIds
                  .filter((feedbackId) => {
                    if (!spritesCycle) {
                      return true;
                    }
                    const {type} = feedbackState.byFeedbackId[feedbackId];
                    return type === spritesCycle;
                  })
                  .map((feedbackId) => {
                    const {htmlAnswer, date, question, type} =
                      feedbackState.byFeedbackId[feedbackId];
                    return (
                      <div key={feedbackId}>
                        <div className={styles['feedback-title']}>
                          <DropboxerCard dropboxer={feedbackGiver} />
                          <Text variant="label">{`${type} - ${date}`}</Text>
                        </div>

                        <TextBubble html={question} />
                        <TextBubble html={htmlAnswer} />
                      </div>
                    );
                  })}
              </List.Content>
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};
