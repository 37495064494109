import type {
  CareerFrameworkMetadata,
  Dropboxer as DropboxerResponse,
} from 'client';

type MessageId = string;
type Message = {
  text?: string;
  type: 'bot_message' | 'user_message' | 'unsent_message';
};
type Messages = {
  [key in MessageId]: Message;
};

export type DropboxerId = string;
export type Dropboxer = Omit<DropboxerResponse, 'employee_id'>;
export type Dropboxers = {
  [key in DropboxerId]: Dropboxer;
};
export type CFId = string;
export type CFMetadata = {
  title: string;
  category: string;
};
export type CareerFrameworkIndividual = Omit<CareerFrameworkMetadata, 'cf_id'>;
export type CareerFrameworkIndividuals = {
  [key in CFId]: CareerFrameworkIndividual;
};
export type IndividualId = CFId | DropboxerId;
export type Individual = CareerFrameworkIndividual | Dropboxer;

type Session = {
  sessionId?: string;
  currentPromptId?: string;
  messageIds: MessageId[];
  messages: Messages;
  currentUpdatedFeedback?: string;
  individual?: Individual;
  expirationTime?: number;
  historyExpirationTime?: number;
  careerFrameworkId?: string;
};
type Sessions = {
  [key in IndividualId]: Session;
};
type SearchState = {
  query: string;
  dropboxerIds: DropboxerId[];
  dropboxers: Dropboxers;
  cfIds: CFId[];
  careerFrameworkIndividuals: CareerFrameworkIndividuals;
};
export enum TypesOfIndividual {
  dropboxer = 'DROPBOXER',
  careerFrameworkIndividual = 'CAREER_FRAMEWORK_INDIVIDUAL',
}

export type SelectedIndividualInfo = {
  individualId: IndividualId;
  individualType: TypesOfIndividual;
};
export type FeedbackChatState = {
  sessions: Sessions;
  selectedIndividualInfo?: SelectedIndividualInfo;
  search: SearchState;
};
