import {FeedbackChatV2} from 'components/feedback_chat/feeback_chat_v2';
import {Helmet} from 'react-helmet';

export const FeedbackV2 = () => {
  return (
    <>
      <Helmet>
        <title>Feedback v2 - SPRiTEs-GPT</title>
      </Helmet>
      <FeedbackChatV2 />
    </>
  );
};
