import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en_us from './en-US.json';

const convertTranslation = (origin: any): {[key: string]: string} => {
  const result: {[key: string]: string} = {};
  const typed = origin as {[key: string]: {message: string}};
  Object.keys(typed).forEach((key) => {
    result[key] = typed[key].message;
  });
  return result;
};

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  lng: 'en-US',
  interpolation: {
    escapeValue: false,
  },
  returnEmptyString: false,
  returnNull: false,
  keySeparator: false,
  resources: {
    'en-US': {
      translation: convertTranslation(en_us),
    },
  },
});

i18n;
