import React from 'react';

export const useTheme = (): 'dark' | 'bright' => {
  const mql = window.matchMedia('(prefers-color-scheme: dark)');

  const [isDark, setIsDark] = React.useState(mql.matches);

  React.useEffect(() => {
    const cb = (e: MediaQueryListEvent) => {
      setIsDark(e.matches);
    };

    mql.addEventListener('change', cb);
    return () => {
      mql.removeEventListener('change', cb);
    };
  }, [mql]);

  return isDark ? 'dark' : 'bright';
};
