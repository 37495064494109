/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DisclaimerResponse } from '../models/DisclaimerResponse';
import type { DisclaimersResponse } from '../models/DisclaimersResponse';
import type { DisclaimerType } from '../models/DisclaimerType';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Read User Me
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public static readUserMeApiV1AuthMeGet(): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/me',
        });
    }

    /**
     * Get Disclaimers
     * Get the disclaimers for the current user by type.
     * If no type is provided, all disclaimers will be returned.
     * @param requestBody
     * @returns DisclaimersResponse Successful Response
     * @throws ApiError
     */
    public static getDisclaimersApiV1DisclaimersGet(
        requestBody?: (Array<DisclaimerType> | null),
    ): CancelablePromise<DisclaimersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/disclaimers',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Set Disclaimer
     * Accept a disclaimer for the current user
     * @param disclaimerType
     * @returns DisclaimerResponse Successful Response
     * @throws ApiError
     */
    public static setDisclaimerApiV1AcceptDisclaimerPost(
        disclaimerType: DisclaimerType,
    ): CancelablePromise<DisclaimerResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accept_disclaimer',
            query: {
                'disclaimer_type': disclaimerType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
