import {Toggle} from '@dropbox/dig-components/controls';
import {Tooltip} from '@dropbox/dig-components/tooltips';
import {Text, Title} from '@dropbox/dig-components/typography';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {OptOutFeatureProps, OptOutFeaturesResponse} from 'client';
import {OptOut} from 'client/models/OptOut';
import React from 'react';
import {getDropboxService} from 'utilities';

import styles from './profile.module.css';

const TOGGLES: {
  [key in OptOut]: {
    id: string;
    label: string;
    description: string;
  };
} = {
  [OptOut.ALL]: {
    id: 'ALL-TOGGLE',
    label: 'All usage of SPRiTEs-GPT for my feedback',
    description:
      'No one will be able to leverage SPRiTEs-GPT to draft reviews or feedback for you',
  },
  [OptOut.MANAGER_ASSISTED_FEEDBACK]: {
    id: 'MANAGER-ASSISTED-FEEDBACK-TOGGLE',
    label: 'Manager using SPRiTEs-GPT for my feedback',
    description:
      'Your manager will not be able to leverage SPRiTEs-GPT to draft reviews for you',
  },
  [OptOut.STAKEHOLDER_FEEDBACK]: {
    id: 'STAKEHOLDER-FEEDBACK-TOGGLE',
    label: 'Stakeholders using SPRiTEs-GPT for my feedback',
    description:
      'Stakeholders will not be able to leverage SPRiTEs-GPT to draft feedback for you',
  },
};

const TOGGLES_ORDER: OptOut[] = [
  OptOut.ALL,
  OptOut.MANAGER_ASSISTED_FEEDBACK,
  OptOut.STAKEHOLDER_FEEDBACK,
];

type OptOutFeaturesStatus = {[key in OptOut]: boolean};

const OPT_OUT_FEATURE_DEFAULTS: OptOutFeaturesStatus = {
  [OptOut.ALL]: false,
  [OptOut.MANAGER_ASSISTED_FEEDBACK]: false,
  [OptOut.STAKEHOLDER_FEEDBACK]: false,
};

const toOptOutFeaturesStatus = (
  optOutFeatures: OptOutFeatureProps[]
): OptOutFeaturesStatus => {
  return optOutFeatures.reduce(
    (prev, optOutFeature) => ({
      ...prev,
      [optOutFeature.opt_out]: optOutFeature.enabled,
    }),
    OPT_OUT_FEATURE_DEFAULTS
  );
};

const useGetPrivacyData = () => {
  const {data, isLoading} = useQuery({
    queryKey: ['/privacy'],
    queryFn: async () => {
      return await getDropboxService().getPrivacyPoliciesApiV1PrivacyGet();
    },
  });

  const optOutFeaturesStatus = toOptOutFeaturesStatus(
    data?.opt_out_features || []
  );

  return {
    isOptOutToggled: (optOut: OptOut) =>
      optOutFeaturesStatus[optOut] || optOutFeaturesStatus[OptOut.ALL],
    isOptOutToggleDisabled: (optOut: OptOut) =>
      optOut !== OptOut.ALL && optOutFeaturesStatus[OptOut.ALL],
    optOutFeaturesStatus,
    isLoading,
  };
};

const useSetPrivacyData = () => {
  const {optOutFeaturesStatus} = useGetPrivacyData();

  const handleOptOutToggle = async (toggledOptOut: OptOut) => {
    return await getDropboxService().setPrivacyPoliciesApiV1PrivacyPost({
      opt_out_features: [
        {opt_out: toggledOptOut, enabled: !optOutFeaturesStatus[toggledOptOut]},
      ],
    });
  };

  const queryClient = useQueryClient();

  const [mutatingOptOuts, setMutatingOptOuts] = React.useState<{
    [key in OptOut]: boolean;
  }>({
    [OptOut.ALL]: false,
    [OptOut.MANAGER_ASSISTED_FEEDBACK]: false,
    [OptOut.STAKEHOLDER_FEEDBACK]: false,
  });

  const {mutate, isLoading} = useMutation({
    mutationFn: handleOptOutToggle,
    onMutate: async (optOut: OptOut) => {
      setMutatingOptOuts((prev) => ({...prev, [optOut]: true}));

      // Cancel any outgoing queries to avoid overwriting the optimistic update
      queryClient.cancelQueries(['/privacy']);

      // Snapshot the current value
      const prevData: OptOutFeaturesResponse | undefined =
        queryClient.getQueryData(['/privacy']);

      // Optimistically update the cache
      queryClient.setQueryData(
        ['/privacy'],
        (prev: OptOutFeaturesResponse | undefined) =>
          prev && {
            ...prev,
            opt_out_features: prev.opt_out_features.map((optOutFeature) =>
              optOutFeature.opt_out === optOut
                ? {
                    ...optOutFeature,
                    enabled: !optOutFeature.enabled,
                  }
                : optOutFeature
            ),
          }
      );
      return {prevData};
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(['/privacy'], context?.prevData);
    },
    onSettled: (_data, _error, variables) => {
      setMutatingOptOuts((prev) => ({...prev, [variables]: false}));
      queryClient.invalidateQueries(['/privacy']);
    },
  });

  const isOptOutMutating = (optOut: OptOut) =>
    mutatingOptOuts[optOut] && isLoading;

  return {
    onOptOutToggle: (optOut: OptOut) => mutate(optOut),
    isOptOutMutating,
  };
};

export const Profile = () => {
  const {isOptOutToggled, isLoading, isOptOutToggleDisabled} =
    useGetPrivacyData();
  const {onOptOutToggle, isOptOutMutating} = useSetPrivacyData();

  const isDisabled = (optOut: OptOut) =>
    isLoading || isOptOutToggleDisabled(optOut) || isOptOutMutating(optOut);

  return (
    <div className={styles['privacy-container']}>
      <Title weightVariant="emphasized">Opt Out:</Title>
      <Text variant="paragraph">
        Dropboxers can choose to opt out of having their colleagues and managers
        use SPRiTEs-GPT to help summarize feedback. If you wish to opt out,
        please select an option below. As a reminder, any information shared
        with OpenAI will not be used to train Open AI’s models and is retained
        for no longer than 30 days.
      </Text>

      {TOGGLES_ORDER.map((optOut) => (
        <Tooltip
          key={`${optOut}-tooltip`}
          title={TOGGLES[optOut].description}
          placement="left"
        >
          <p
            key={`${optOut}-paragraph`}
            className={styles['privacy-toggle-container']}
          >
            <Toggle
              id={TOGGLES[optOut].id}
              isToggled={isOptOutToggled(optOut)}
              onClick={() => onOptOutToggle(optOut)}
              disabled={isDisabled(optOut)}
            />
            <Text
              tagName="label"
              htmlFor={TOGGLES[optOut].id}
              size="large"
              color={isDisabled(optOut) ? 'faint' : 'standard'}
            >
              {TOGGLES[optOut].label}
            </Text>
          </p>
        </Tooltip>
      ))}
    </div>
  );
};
