import {
  CareerFrameworksService,
  DropboxerService,
  LoginService,
  OpenAiService,
  UserService,
} from 'client';
import {OpenAPI} from 'client/core/OpenAPI';
import {TFunction} from 'i18next';

const configOpenApi = () => {
  OpenAPI.BASE = process.env.REACT_APP_API_URL ?? '';
  OpenAPI.CREDENTIALS = 'include';
  OpenAPI.WITH_CREDENTIALS = true;
};

const ORIGIN = window.location.origin;
export const API_URL = process.env.REACT_APP_API_URL ?? '';
export const LOGIN_URL = `${API_URL}/api/v1/auth/login?redirect_uri=${ORIGIN}`;
export const LOGOUT_URL = `${API_URL}/api/v1/auth/logout?post_logout_redirect_uri=${ORIGIN}`;

export const pluralize = (
  count: number,
  copy: string,
  t: TFunction<'translation', undefined, 'translation'>
) => {
  return count === 1
    ? t(copy, {
        count: count,
      })
    : t(copy + '_plural', {
        count: count,
      });
};

export const nameToInitials = (name: string) => {
  return name
    .split(' ')
    .slice(0, 2)
    .map((word) => word[0])
    .join('')
    .toUpperCase();
};

export const getAuthService = () => {
  configOpenApi();
  return UserService;
};

export const getLoginService = () => {
  configOpenApi();
  return LoginService;
};

export const getOpenAiService = () => {
  configOpenApi();
  return OpenAiService;
};

export const getDropboxService = () => {
  configOpenApi();
  return DropboxerService;
};

export const getCareerFrameworksService = () => {
  configOpenApi();
  return CareerFrameworksService;
};
