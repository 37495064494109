import {Layout} from 'components/Layout';
import {PageSpinner} from 'components/shared/PageSpinner';
import {useEffect} from 'react';
import {LOGIN_URL} from 'utilities';

const Login = () => {
  useEffect(() => {
    window.location.replace(LOGIN_URL);
  }, []);

  return (
    <Layout>
      <PageSpinner />
    </Layout>
  );
};

export {Login};
