import styles from './Layout.module.css';
import {TopNavbar} from './TopNavbar';

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = ({children}: LayoutProps) => (
  <>
    <TopNavbar />
    <div className={styles.container}>{children}</div>
  </>
);

export {Layout};
