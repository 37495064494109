import {Profile} from 'components/profile/profile';
import {Helmet} from 'react-helmet';
import {useAppSelector} from 'store';
import {getPulseUser} from 'store/features/auth/selectors';

export const ProfilePage = () => {
  const curUser = useAppSelector(getPulseUser);
  return (
    <>
      <Helmet>
        <title>{`${curUser?.display_name} - SPRiTEs-GPT`}</title>
      </Helmet>
      <Profile />
    </>
  );
};
