import React from 'react';
import {useSearchParams} from 'react-router-dom';

/*
 * A hook that provides a stateful value for a query parameter.
 *
 * @param paramKey - The key of the query parameter.
 * @param options - An optional object that can contain a `defaultValue` field to
 * set the default value of the query parameter if it is not present in the URL.
 * @returns A tuple containing the current value of the query parameter and a
 * function to set the value of the query parameter.
 */
export const useQueryParamState = (
  paramKey: string,
  options?: {defaultValue: string}
): readonly [string, (value: string | undefined) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = searchParams.get(paramKey) ?? options?.defaultValue ?? '';

  const setParamValue = React.useCallback(
    (value: string | undefined) => {
      setSearchParams((prevSearchParams) => {
        if (!value) {
          prevSearchParams.delete(paramKey);
        } else {
          prevSearchParams.set(paramKey, value);
        }

        return prevSearchParams;
      });
    },
    [paramKey, setSearchParams]
  );

  React.useEffect(() => {
    if (!searchParams.get(paramKey) && options?.defaultValue) {
      setParamValue(options?.defaultValue);
    }
  }, [options?.defaultValue, paramKey, searchParams, setParamValue]);

  return [paramValue, setParamValue] as const;
};
