import {ReactNode, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const SET_TOKEN_EVENT = 'set_sprites_gpt_token';
const SPRITES_GPT_TOKEN_KEY = 'sprites_gpt_token';

export const setToken = (token: string) => {
  localStorage.setItem(SPRITES_GPT_TOKEN_KEY, token);
  window.dispatchEvent(new Event(SET_TOKEN_EVENT));
};

const fetchToken = () => {
  return localStorage.getItem(SPRITES_GPT_TOKEN_KEY) ?? '';
};

export const RequireToken = ({children}: {children?: ReactNode}) => {
  const [userToken, setUserToken] = useState(fetchToken);
  const navigate = useNavigate();

  // listen for local storage token changes so we rerender after it is set and don't show a blank page.
  useEffect(() => {
    const handleStorageChange = () => {
      setUserToken(fetchToken());
    };

    window.addEventListener(SET_TOKEN_EVENT, handleStorageChange);

    return () => {
      window.removeEventListener(SET_TOKEN_EVENT, handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let timeoutId: any;

    if (userToken === '') {
      timeoutId = setTimeout(() => {
        navigate('/login', {replace: true});
      }, 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, userToken]);

  if (userToken !== '') {
    return <>{children}</>;
  }

  return null;
};
