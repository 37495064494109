/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DropboxersResponse } from '../models/DropboxersResponse';
import type { FeedbackResponse } from '../models/FeedbackResponse';
import type { OptOutFeaturesRequest } from '../models/OptOutFeaturesRequest';
import type { OptOutFeaturesResponse } from '../models/OptOutFeaturesResponse';
import type { ReportsResponse } from '../models/ReportsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DropboxerService {

    /**
     * Refresh All Dropboxers
     * Refreshes all dropboxers in the database with the latest data from Workday
     * @returns any Successful Response
     * @throws ApiError
     */
    public static refreshAllDropboxersApiV1DropboxerRefreshPut(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/dropboxer/refresh',
        });
    }

    /**
     * Refresh All Feedback
     * Refreshes all feedback in the database with the latest data from Workday
     * @returns any Successful Response
     * @throws ApiError
     */
    public static refreshAllFeedbackApiV1DropboxerRefreshFeedbackPut(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/dropboxer/refresh/feedback',
        });
    }

    /**
     * Get All Dropboxers
     * Returns all dropboxers in the database
     * @returns DropboxersResponse Successful Response
     * @throws ApiError
     */
    public static getAllDropboxersApiV1DropboxerAllGet(): CancelablePromise<DropboxersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dropboxer/all',
        });
    }

    /**
     * Search Dropboxer By Name Or Email
     * Returns a list of dropboxers that match the given name or email
     * @param name
     * @param limit
     * @returns DropboxersResponse Successful Response
     * @throws ApiError
     */
    public static searchDropboxerByNameOrEmailApiV1DropboxerSearchGet(
        name: string,
        limit: number = 3,
    ): CancelablePromise<DropboxersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dropboxer/search',
            query: {
                'name': name,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Reports By Name Or Email
     * Searches for all hierarchical reports of the current user or the given employee by name or email
     * @param employeeId If provided, will search within the reports of this employee
     * @param name The name or email to search for
     * @param limit The maximum number of results to return
     * @returns DropboxersResponse Successful Response
     * @throws ApiError
     */
    public static searchReportsByNameOrEmailApiV1DropboxerSearchReportsGet(
        employeeId?: (string | null),
        name: string = '',
        limit: number = 3,
    ): CancelablePromise<DropboxersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dropboxer/search_reports',
            query: {
                'employee_id': employeeId,
                'name': name,
                'limit': limit,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Reports
     * Returns the direct and indirect reports of the current user or the given employee
     * @param employeeId
     * @returns ReportsResponse Successful Response
     * @throws ApiError
     */
    public static getReportsApiV1DropboxerGetReportsGet(
        employeeId?: (string | null),
    ): CancelablePromise<ReportsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dropboxer/get_reports/',
            query: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Feedback
     * Returns the feedback for a given employee. Will error if current user is
     * not a manager of the given employee or self.
     * @param employeeId
     * @returns FeedbackResponse Successful Response
     * @throws ApiError
     */
    public static getFeedbackApiV1DropboxerFeedbackEmployeeIdGet(
        employeeId: string,
    ): CancelablePromise<FeedbackResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/dropboxer/feedback/{employee_id}',
            path: {
                'employee_id': employeeId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Privacy Policies
     * Get all the privacy policies for a user
     * @returns OptOutFeaturesResponse Successful Response
     * @throws ApiError
     */
    public static getPrivacyPoliciesApiV1PrivacyGet(): CancelablePromise<OptOutFeaturesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/privacy',
        });
    }

    /**
     * Set Privacy Policies
     * Set privacy policies for a user
     * @param requestBody
     * @returns OptOutFeaturesResponse Successful Response
     * @throws ApiError
     */
    public static setPrivacyPoliciesApiV1PrivacyPost(
        requestBody: OptOutFeaturesRequest,
    ): CancelablePromise<OptOutFeaturesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/privacy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
